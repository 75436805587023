import { authHeaders } from "collection/graphql/client/common";
import convertKeysToLegacy from "collection/graphql/recipes/hooks/lib/convertKeysToLegacy";

/**
 * Saves a {@link ProductRecipeIngredient}. Handles both creates and updates.
 *
 * @param {ProductRecipeIngredient} ingredient
 * @return {Promise<Response>}
 */
const headers = authHeaders();
headers["Content-Type"] = "application/json";

async function saveRecipeIngredient(ingredient) {
  let method = "POST";
  let url = "/v2.0/api/product_recipe_components";
  if (ingredient.id > 0) {
    method = "PUT";
    url += `/${ingredient.id}`;
  }

  return fetch(url, {
    body: JSON.stringify(convertKeysToLegacy(ingredient)),
    headers,
    method,
  });
}

export default saveRecipeIngredient;
