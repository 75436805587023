import { useSuspenseQuery } from "@apollo/client";
import { css } from "aphrodite/no-important";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import { pick } from "lodash";
import React, { useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useUserConfig } from "collection/graphql/config";
import useFieldCropMutations from "collection/graphql/fieldCrops/hooks/useFieldCropMutations";
import useFieldMutations from "collection/graphql/fields/hooks/useFieldMutations";
import useFieldStats from "collection/graphql/fields/hooks/useFieldStats";
import getField from "collection/graphql/fields/queries/getField";
import { getCurrentSubscription } from "collection/graphql/subscription";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import useMarketingAdvisor from "hooks/useMarketingAdvisor";
import usePermissions from "hooks/usePermissions";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import App from "layout/app";
import { ONBOARDING_VIEWED_FIRST_FIELD_REPORT } from "lib/metrics/events";
import { STANDARD } from "model/Subscription/constants";

import { Button } from "components/fl-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import AddMap from "fields/add/AddMap";
import GetStartedModal from "fields/components/add/GetStartedModal";
import styles from "fields/onboarding/onboardingStyles";

const FieldAdd = () => {
  const id = +useParams().id;
  useDocumentTitle(id ? "Edit Field" : "Add field");
  const { createField, updateField } = useFieldMutations();
  const { createFieldCrop } = useFieldCropMutations();
  const currentCropYear = useCurrentCropYear()[0];
  const { totalFields } = useFieldStats();
  const isFirstField = useMemo(() => totalFields === 0, []);
  const navigate = useNavigate();

  const [hasChanged, setHasChanged] = useState(false);
  const [showGetStartedModal, setShowGetStartedModal] = useState(isFirstField);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [clearMap, setClearMap] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { data } = useSuspenseQuery(getField, {
    skip: !id,
    variables: {
      fieldId: id,
    },
  });

  const [formData, setFormData] = useState(() => {
    if (data?.field) {
      return pick(data.field, ["acreage", "geometry", "geometricCircumference", "id", "name"]);
    } else {
      return { commodityId: null };
    }
  });

  const { currentPlan } = useRestSuspenseQuery(getCurrentSubscription).data.subscription;
  const isOnStandardPlan = currentPlan?.id === STANDARD;
  const initialFieldCount = useMemo(() => totalFields, []);

  const userConfig = useUserConfig();
  const initialDidOnboardingState = useMemo(() => userConfig("didOnboarding"), []);

  const { isMarketingAdvisor } = useMarketingAdvisor();
  const hasRequiredPermissions = usePermissions().hasPermission("fields", ["read", "write"]);

  const canAddFields =
    !isMarketingAdvisor && (!isOnStandardPlan || (initialFieldCount === 0 && !initialDidOnboardingState));

  if (!hasRequiredPermissions || !canAddFields) {
    navigate("/fields", { replace: true });
  }

  const handleCancel = async () => {
    if (hasChanged) {
      await App.confirm({
        title: "Discard Changes?",
        message: "Changes you made to your field will not be saved.",
        confirm: "Yes, Discard Changes",
        cancel: "Keep Editing",
      });
    }

    navigate(-1);
    return null;
  };

  const handleChange = (newData) => {
    setFormData((formData) => ({ ...formData, ...newData }));

    if (!hasChanged) {
      setHasChanged(true);
    }
  };

  const handleReset = () => {
    setFormData(data);
    setHasChanged(false);
    setClearMap(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const { commodityId, ...rest } = formData;
    const fieldData = pick(rest, ["acreage", "geometry", "geometricCircumference", "id", "name"]);

    if (fieldData.id) {
      await updateField(fieldData);
      navigate(-1);
      return null;
    } else {
      const field = await createField(fieldData);

      if (commodityId) {
        const crop = { commodityId, cropYear: currentCropYear, fieldId: field.id };
        await createFieldCrop(crop);
      }

      setIsSaving(false);
      if (isFirstField) {
        setFormData(field);
        ONBOARDING_VIEWED_FIRST_FIELD_REPORT.track({});
        navigate(`/fields/onboarding-dashboard/${field.id}`, { replace: true });
      } else {
        setShowSuccessModal(true);
      }
    }
  };

  const resetMap = () => {
    setShowSuccessModal(false);
    setClearMap(true);
  };

  return (
    <AddMap
      clearMap={clearMap}
      data={formData}
      firstTime={isFirstField}
      isSaving={isSaving}
      onChange={handleChange}
      onExit={handleCancel}
      onReset={handleReset}
      onSave={handleSave}
      ready={!showGetStartedModal}
    >
      <>
        {showGetStartedModal && <GetStartedModal onClose={() => setShowGetStartedModal(false)} />}
        {showSuccessModal && (
          <Modal>
            <ModalHeader>
              <ModalTitle>Field added</ModalTitle>
            </ModalHeader>

            <ModalBody>{`You've successfully added the field "${formData.name}"`}</ModalBody>
            <ModalFooter className={css(styles.modalFooter)}>
              <Link className="btn btn-lg btn-primary" to="/fields">
                Done for now
              </Link>
              <Button
                className={css(styles.fieldAddPrimaryButton)}
                color="primary"
                display="inline"
                onClick={resetMap}
                size="lg"
              >
                Add another field
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    </AddMap>
  );
};

export default FieldAdd;
