import PropTypes from "prop-types";
import React from "react";

import GlobalContextProvider from "context/GlobalContextProvider";
import { SSOContextProvider } from "context/SSOContext";

import PrimaryBanner from "components/banners/PrimaryBanner";
import Navigation from "components/fl-ui/Layout/Navigation";
import ContentContainer from "components/fl-ui/Layout/Navigation/ContentContainer";
import Toast from "components/fl-ui/Toast";

const App = ({ children }) => {
  //All the convoluted auth logic that used to live here was moved to the new Application.jsx file
  // to play better with react router
  return (
    <>
      <PrimaryBanner />
      <div id="appContent">
        <Navigation />
        <ContentContainer>{children}</ContentContainer>
        <Toast />
      </div>
    </>
  );
};

App.propTypes = {
  children: PropTypes.node,
};

export default ({ children }) => (
  <GlobalContextProvider>
    <SSOContextProvider>
      <App>{children}</App>
    </SSOContextProvider>
  </GlobalContextProvider>
);
