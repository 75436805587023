import { authHeaders } from "collection/graphql/client/common";

/**
 * Performs a DELETE operation for a given recipe.
 *
 * @param {ProductRecipe|Number} recipeId
 * @return {Promise<Response>}
 */
function deleteRecipe(recipeId) {
  const headers = authHeaders();
  headers["Content-Type"] = "application/json";

  return fetch(`/v2.0/api/product_recipes/${recipeId}`, {
    headers,
    method: "DELETE",
  });
}

export default deleteRecipe;
