/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";

import { authHeaders } from "collection/graphql/client/common";

const getLink = (params) => {
  const searchParams = new URLSearchParams(params);
  return `/v2.0/planner/reports/download?${searchParams.toString()}`;
};

const withData = (Component) => (props) => {
  const { id, parameters } = props;
  const [abortController, setAbortController] = useState({ abort: () => {} });
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const headers = authHeaders();
  headers["Content-Type"] = "application/json";

  useEffect(() => {
    abortController.abort();
    const controller = new AbortController();
    const format = "json";
    const url = getLink({ id, format, ...parameters });

    setAbortController(controller);
    setError(null);
    setLoading(true);

    fetch(url, {
      cache: "no-store",
      headers,
      signal: controller.signal,
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setLoading(false);
      } else {
        setError("Report execution failed");
        setLoading(false);
      }
    });
  }, [parameters]);

  return <Component {...props} data={data} loading={loading} error={error} />;
};

export default withData;
