import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { setIsSSOOnly } from "modules/auth/utils/isSSOOnly";
import isUserSSOOnly from "modules/auth/utils/isUserSSOOnly";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, Input } from "components/fl-ui/Form";

const email = yup.string().email().label("Email").required();

const EmailCheckFormSchema = yup.object().shape({
  email,
});

const EmailCheckForm = styled(({ className, setSSOUserEmail }) => {
  const methods = useForm({
    defaultValues: EmailCheckFormSchema.cast(
      {
        email: "",
      },
      { assert: false, stripUnknown: true }
    ),
    mode: "onTouched",
    resolver: yupResolver(EmailCheckFormSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async ({ email }) => {
    const { sso_only } = await isUserSSOOnly(email);
    if (sso_only) {
      setIsSSOOnly(true);
      setSSOUserEmail(email);
    } else {
      setIsSSOOnly(false);
      navigate(`/login?login_hint=${encodeURIComponent(email)}`, { trigger: true });
    }
  };

  return (
    <div className={className}>
      <FormProvider {...methods} schema={EmailCheckFormSchema}>
        <br />
        <p className="top-text">Please provide your email to login:</p>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledFormGroup
            name="email"
            render={({ field }) => <Input {...field} autoComplete="email" placeholder="your@email.com" type="email" />}
          />
          <br />
          <Button className="save-button" color="primary" disabled={!_.isEmpty(methods.formState.errors)} type="submit">
            Next
          </Button>
        </Form>
      </FormProvider>
    </div>
  );
})`
  .save-button {
    width: 100%;
  }

  .top-text {
    text-align: left;
  }
`;

export default EmailCheckForm;
