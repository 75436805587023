import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import { useSSOContext } from "context/SSOContext";
import { getIsSSOOnly } from "modules/auth/utils/isSSOOnly";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const ProtectedRouteLayout = () => {
  const { isLoggedIn, isLoggingOut, loading: loadingAuth } = useAuth();
  const { selectedEnterprise } = useSSOContext();

  if (loadingAuth || isLoggingOut) {
    return <LoadingWrapper isLoading />;
  } else if (!loadingAuth && !isLoggedIn) {
    return <Navigate to="/sign_in" />;
  } else if (!loadingAuth && isLoggedIn && !selectedEnterprise && getIsSSOOnly()) {
    return <Navigate to="/sso/select-enterprise" />;
  }

  return <Outlet />;
};

export default ProtectedRouteLayout;
