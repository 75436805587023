"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("collection/graphql/client/common");
const isUserSSOOnly = (email) => __awaiter(void 0, void 0, void 0, function* () {
    const headers = (0, common_1.authHeaders)() ? (0, common_1.authHeaders)() : {};
    headers["Content-Type"] = "application/json";
    const response = yield fetch("/v2.0/lookup/sso_only", {
        body: JSON.stringify({ email }),
        cache: "no-store",
        headers,
        method: "POST",
    });
    if (!response.ok) {
        throw new Error("An error occurred while requesting if the user is SSO only");
    }
    return response.json();
});
exports.default = isUserSSOOnly;
