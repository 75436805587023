import { datadogLogs } from "@datadog/browser-logs";
import useAnalyticsSetup from "app/hooks/useAnalyticsSetup";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import useMobileListeners from "app/hooks/useMobileListeners";
import useODICEvents from "app/hooks/useODICEvents";
import useSentrySetup from "app/hooks/useSentrySetup";
import _ from "lodash";
import React, { Suspense, useEffect, useRef } from "react";
import { useAuth as useSSOAuth } from "react-oidc-context";
import { Outlet } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import { ssoUserVar } from "collection/graphql/client/common";
import { SSOContextProvider } from "context/SSOContext";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import EventChannel from "lib/events";
import { getIsSSOOnly } from "modules/auth/utils/isSSOOnly";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

//TODO: All of this logic was removed from app.jsx and moved to this file,
// ideally we remove this completely once we understand it better
const OldApplicationLogicWrapper = ({ children }) => {
  const auth = useSSOAuth();
  getIsSSOOnly();

  const { isLoggedIn, isLoggingOut, loading: authIsLoading } = useAuth();

  // legacy auth enterprise
  const { currentEnterprise, loading: enterpriseLoading } = useCurrentEnterprise();
  const lastEnterpriseId = useRef();
  const hasEnterpriseChanged = !enterpriseLoading && lastEnterpriseId.current !== currentEnterprise?.id;

  if (hasEnterpriseChanged) {
    lastEnterpriseId.current = currentEnterprise?.id;
  }

  const isSyncing = [authIsLoading, isLoggingOut, enterpriseLoading && !lastEnterpriseId.current].some((x) => x);

  useODICEvents();
  useDocumentTitle();
  useSentrySetup();
  useMobileListeners();
  useAnalyticsSetup();

  useEffect(() => {
    if (getIsSSOOnly()) {
      //NEW SSO AUTH
      //TODO: I'm honestly not sure how valid this is now with the react router refactor
      if (!auth.isLoading) {
        if (auth.isAuthenticated) {
          ssoUserVar(auth.user);

          if (_.isEqual({}, datadogLogs.getUser())) {
            datadogLogs.setUser({
              id: auth.user.profile.bushel_id,
              name: auth.user.profile.name,
              email: auth.user.profile.email,
            });
          }
        }
        EventChannel.getChannel("app").fire("ready");
      }
    } else {
      // LEGACY AUTH
      const keycloakStorageString = `${process.env.KEYCLOAK_AUTH_ISSUER}:${process.env.KEYCLOAK_CLIENT_ID}`;
      // make sure the sso token is cleared from localStorage
      localStorage.removeItem(`oidc.user:${keycloakStorageString}`);
      localStorage.removeItem(`enterprise:${keycloakStorageString}`);

      if (!isSyncing) {
        EventChannel.getChannel("app").fire("ready");
      }
    }
  }, [auth.isAuthenticated, auth.isLoading, isSyncing, isLoggedIn]);

  return children;
};

const Application = () => {
  return (
    <Suspense fallback={<LoadingWrapper isLoading />}>
      <SSOContextProvider>
        <OldApplicationLogicWrapper>
          <Outlet />
        </OldApplicationLogicWrapper>
      </SSOContextProvider>
    </Suspense>
  );
};

export default Application;
