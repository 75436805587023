import leaflet from "leaflet";

import { authHeaders } from "collection/graphql/client/common";

import { CropsColorPalette } from "components/fl-ui/colors/palette";

const defaultStyle = {
  color: "white",
  cursor: "pointer",
  fill: "white",
  fillOpacity: 0.3,
  opacity: 0.8,
  stroke: "white",
  weight: 1.5,
};

const highlight = (geoJSONLayer, cluLayer) => {
  const { edge, ie, opera } = leaflet.Browser;

  if (!cluLayer.selectedIds.has(cluLayer.getLayerId(geoJSONLayer))) {
    geoJSONLayer.setStyle({
      ...defaultStyle,
      color: CropsColorPalette["light-green"],
      fill: CropsColorPalette["light-green"],
      fillOpacity: 0.3,
      stroke: CropsColorPalette["light-green"],
    });
  }

  if (!ie && !opera && !edge) {
    geoJSONLayer.bringToFront();
  }
};

const resetHighlight = (geoJSONLayer, cluLayer) => {
  if (!cluLayer.selectedIds.has(cluLayer.getLayerId(geoJSONLayer))) {
    cluLayer.layers.resetStyle(geoJSONLayer);
  }
};

const select = (geoJSONLayer, cluLayer) => {
  if (cluLayer.selectedIds.has(cluLayer.getLayerId(geoJSONLayer))) {
    cluLayer.selectedIds.delete(cluLayer.getLayerId(geoJSONLayer));
    geoJSONLayer.setStyle(defaultStyle);
  } else {
    cluLayer.selectedIds.add(cluLayer.getLayerId(geoJSONLayer));
    geoJSONLayer.setStyle({
      ...defaultStyle,
      color: CropsColorPalette["green"],
      fill: CropsColorPalette["green"],
      fillOpacity: 0.7,
      opacity: 1,
      stroke: CropsColorPalette["green"],
    });
  }

  cluLayer.updateSelectedGeoJSON();
};

class Clus extends leaflet.Layer {
  requests = 0;
  selectedIds = new Set();

  getSelectedGeoJSON() {
    const features = this.features?.filter(({ properties }) => this.selectedIds.has(properties.id));

    if (features.length === 1) {
      return { geometry: features[0].geometry };
    }

    return {
      geometry: {
        coordinates: features.map((feature) => _.cloneDeep(feature.geometry.coordinates)),
        type: "MultiPolygon",
      },
    };
  }

  updateSelectedGeoJSON() {
    if (this.selectedIds.size) {
      return this.map.fire("clu:selected", this.getSelectedGeoJSON());
    } else {
      return this.map.fire("clu:cancelled");
    }
  }

  onAdd(map) {
    this.map = map;
    return this;
  }

  onRemove() {
    this.reset();
    this.features = null;
    this.layers?.removeFrom(this.map);
    this.layers = null;
    this.map = null;
  }

  getLayerId(layer) {
    return layer.feature.properties.id;
  }

  reset() {
    this.selectedIds = new Set();
    this.layers?.resetStyle();
  }

  onEachFeature = (feature, layer) => {
    if (this.selectedIds.has(this.getLayerId(layer))) {
      layer.setStyle({
        ...defaultStyle,
        color: CropsColorPalette["green"],
        fill: CropsColorPalette["green"],
        fillOpacity: 0.7,
        opacity: 1,
        stroke: CropsColorPalette["green"],
      });
    }

    layer.on({
      click: () => select(layer, this),
      mouseout: () => resetHighlight(layer, this),
      mouseover: () => highlight(layer, this),
    });
  };

  async load(ne, sw) {
    ++this.requests;
    const mapCanvas = document.getElementById("map_canvas");
    if (mapCanvas) {
      mapCanvas.style.cursor = "wait";
    }

    // a GEOJSON polygon made my completing the rectangle using the ne and sw points as corners
    const polygonGeometry = {
      type: "Polygon",
      coordinates: [
        [
          [ne.lng, ne.lat],
          [sw.lng, ne.lat],
          [sw.lng, sw.lat],
          [ne.lng, sw.lat],
          [ne.lng, ne.lat],
        ],
      ],
    };

    const url = `/v2.0/clus?geometry=${JSON.stringify(polygonGeometry)}`;
    const headers = authHeaders();
    const response = await fetch(url, {
      headers,
      cache: "force-cache",
      credentials: "same-origin",
    });
    if (response.ok) {
      const data = await response.json();
      if (this.map) {
        this.features = data;
        this.layers?.removeFrom(this.map);
        this.layers = leaflet
          .geoJSON(data, {
            style: () => defaultStyle,
            onEachFeature: this.onEachFeature,
          })
          .addTo(this.map);
      }

      --this.requests;
      if (!this.requests) {
        if (mapCanvas) {
          mapCanvas.style.cursor = "auto";
        }
      }
    }
  }
}

export default new Clus();
