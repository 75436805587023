"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SSORoutes = {
    bushelContracts: "/sso/successfully-connected-to-bushel-contracts",
    confirmFarmName: "/sso/confirm-farm-name",
    connectToContracts: "/sso/connect-to-contracts",
    fieldsSurvey: "/fields/survey",
    loading: "/sso",
    login: "/login",
    selectEnterprise: "/sso/select-enterprise",
    signIn: "/sign_in",
    signUp: "/sso/sign-up",
    ssoComplete: "/fields",
    userInformation: "/sso/user-information",
};
const completeSSOFlow = (currentRoute, hasFields) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentlyOnSSORoute = Object.values(SSORoutes).includes(currentRoute);
    // If the user completes the SSO flow but their currentRoute is NOT one of the SSORoutes, they should just remain on the same page
    if (!currentlyOnSSORoute) {
        return currentRoute;
    }
    else {
        return hasFields ? SSORoutes.ssoComplete : SSORoutes.fieldsSurvey;
    }
};
const navigateThroughSSOFlow = ({ currentRoute, enterprises, firstAndLastNameExist, hasBeenPromptedToConnectContracts, hasFarmName, hasFields, isContractsConnected, isUserAdmin, isUserInBushelNetwork, isSSOOnly, loading, selectedEnterprise, successfullyConnectedToContracts, }) => {
    if (loading) {
        return SSORoutes.loading;
    }
    if (!isSSOOnly) {
        return SSORoutes.login;
    }
    if ((enterprises === null || enterprises === void 0 ? void 0 : enterprises.length) === 0) {
        return SSORoutes.signUp;
    }
    if (!selectedEnterprise) {
        return SSORoutes.selectEnterprise;
    }
    if (!firstAndLastNameExist) {
        return SSORoutes.userInformation;
    }
    if (!isUserAdmin) {
        return completeSSOFlow(currentRoute, hasFields);
    }
    if (!hasFarmName) {
        return SSORoutes.confirmFarmName;
    }
    if (!isUserInBushelNetwork) {
        return completeSSOFlow(currentRoute, hasFields);
    }
    if (isContractsConnected) {
        return completeSSOFlow(currentRoute, hasFields);
    }
    if (!hasBeenPromptedToConnectContracts) {
        return SSORoutes.connectToContracts;
    }
    if (successfullyConnectedToContracts) {
        return SSORoutes.bushelContracts;
    }
    return completeSSOFlow(currentRoute, hasFields);
};
exports.default = navigateThroughSSOFlow;
